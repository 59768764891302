<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form q-pa-md" style="width: 460px">
      <SendDocumentsComponent
        :showUserDocuments="false"
        :defaultSuccessMsg="'account.my_docs.no_docs'"
      />

      <q-card-actions align="right" class="q-mt-md">
        <q-btn flat :label="$t('global.close')" color="dark" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import SendDocumentsComponent from "@/components/SendDocuments/SendDocumentsComponent.vue";

export default {
  name: "UploadDocumentComponent",

  components: {
    SendDocumentsComponent,
  },
};
</script>

<style scoped>
.form {
  width: 600px;
  border-top: 15px solid rgb(206, 206, 206);
}

@media screen and (max-width: 700px) {
  .form {
    width: 100%;
  }
}
</style>
